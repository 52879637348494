<template>
  <v-row align="center" justify="center">
    <v-col cols="12" align="center" justify="center">
      <div class="video-panel" id="video-panel"></div>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "telepresence",
  computed: {
    ...mapGetters(["getKioskProfile", "isUserLooking"]),
  },
  mounted() {
    this.$store.dispatch(
      "avatarSpeak",
      "Hang on! I am trying to connect to the live person."
    );
    this.$store.commit("setPageHeader", "");
    console.log(
      "CREATE VIDEO BOX CALLED:",
      this.getKioskProfile.data().videoLink
    );
    const domain = this.getKioskProfile.data().videoLink;
    // const domain = "clara-connect1.arsconnect.com"
    const options = {
      roomName: this.getKioskProfile.data().name,
      width: 1500,
      height: window.innerHeight - 350,
      parentNode: document.querySelector("#video-panel"),
      interfaceConfigOverwrite: {
        filmStripOnly: false,
        TOOLBAR_BUTTONS: [],
        TOOLBAR_ALWAYS_VISIBLE: false,
        HIDE_INVITE_MORE_HEADER: true,
        DISABLE_VIDEO_BACKGROUND: true,
        DEFAULT_BACKGROUND: "#FFF",
      },
      userInfo: {
        email: "",
        displayName: this.getKioskProfile.data().name,
      },
    };
    window.JitsiMeetExternalAPI =
      window.JitsiMeetExternalAPI || window.exports.JitsiMeetExternalAPI;
    const api = new window.JitsiMeetExternalAPI(domain, options);
    api.addListener("participantLeft", () => {
      this.noOfParticipants = api.getNumberOfParticipants();
      console.log("NO OF PARTICIPANTS:", this.noOfParticipants);
      if (this.noOfParticipants === 1) {
        this.$store.commit(
          "setTextBubbleText",
          "Tap the microphone button, to ask me a question."
        );
        this.$router.push({ path: "/" });
      }
    });

    api.addListener("participantJoined", () => {
      this.noOfParticipants = api.getNumberOfParticipants();
      console.log("Total number of participant", this.noOfParticipants);
      if (this.noOfParticipants >= 1) {
        clearTimeout(callEndTimeout);
        console.log("Total number of participant speech deleted");
        this.$store.commit("clearTextBubbleText");
      }
    });
    console.log(api);

    let callEndTimeout = setTimeout(() => {
      const that = this;
      that.$store.dispatch("avatarSpeak", "Please try again after sometime.");
      that.$router.push({ path: "/" });
      clearTimeout(callEndTimeout);
      if (!that.isUserLooking) {
        that.$store.dispatch("resetKiosk");
      }
    }, 25000);
  },
  // beforeDestroy () {
  //     this.$root.$el.parentNode.removeChild(this.$root.$el)
  // },
};
</script>
