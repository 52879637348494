<template>
  <v-row no-gutters class="d-flex justify-space-between">
    <v-col @click="changeLanguage('en-US')" cols="5" align="right">
      <v-avatar style="z-index: 1" size="65">
        <v-img src="@/assets/en.png"></v-img>
      </v-avatar>
      <v-btn elevation="0" large class="ml-n9 pl-10 py-7"> English </v-btn>
    </v-col>
    <v-col @click="changeLanguage('es-US')" cols="5" align="left">
      <v-avatar style="z-index: 1" size="65">
        <v-img src="@/assets/es.png"></v-img>
      </v-avatar>
      <v-btn elevation="0" large class="ml-n9 pl-10 py-7"> Spanish </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "languageBar",
  computed: {
    ...mapGetters(["getTouchCount"]),
  },
  methods: {
    changeLanguage(language) {
      switch (language) {
        case "en-US":
          this.$store.commit("setTouchCount", this.getTouchCount + 1);
          this.$i18n.locale = "en";
          this.$store.commit("setCurrentLanguage", "en-US");
          this.$store.dispatch("avatarSpeak", "I am now speaking in english");
          this.$store.commit("setTouchRequest", {
            module: "Language Bar",
            action: "Change Language",
            response: "Language changed to English",
            timeStamp: new Date(),
            requestType: "Touch",
          });
          break;
        case "es-US":
          this.$store.commit("setTouchCount", this.getTouchCount + 1);
          this.$i18n.locale = "es";
          this.$store.commit("setCurrentLanguage", "es-US");
          this.$store.dispatch(
            "avatarSpeak",
            "El reconocimiento de voz está desactivado para el idioma español."
          );
          this.$store.commit("setTouchRequest", {
            module: "Language Bar",
            action: "Change Language",
            response: "Language changed to Spanish",
            timeStamp: new Date(),
            requestType: "Touch",
          });
          //this.$store.dispatch('avatarSpeak', 'Estoy aprendiendo español. Por favor regresa más tarde.');
          break;
        default:
          break;
      }
    },
  },
};
</script>
