<template>
  <v-row no-gutters>
    <v-col cols="12" align="center">
      <v-card width="90%" height="700" flat>
        <v-stepper v-model="e1">
          <v-stepper-header>
            <v-stepper-step :complete="e1 > 1" step="1">
              <span class="text-h5 primary--text">Personal Information</span>
            </v-stepper-step>
            <v-divider></v-divider>

            <v-stepper-step :complete="e1 > 2" step="2" class="pa-2">
              <span class="text-h5 primary--text">Motion For Request</span>
            </v-stepper-step>
            <v-divider></v-divider>

            <v-stepper-step step="3">
              <span class="text-h5 primary--text">Confirmation</span>
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <validation-observer ref="observer" v-slot="{ invalid }">
                <v-form>
                  <v-row>
                    <v-col cols="6">
                      <v-menu offset-y :close-on-content-click="false">
                        <template v-slot:activator="{ on, attrs }">
                          <validation-provider
                            v-slot="{ errors }"
                            name="Name"
                            rules="required|max:30"
                          >
                            <v-text-field
                              v-model="name"
                              label="Full Name"
                              :rules="nameRules"
                              :error-messages="errors"
                              @click="setInputField('name')"
                              v-bind="attrs"
                              v-on="on"
                              autocomplete="chrome-off"
                              @input="onInputChange"
                            ></v-text-field>
                          </validation-provider>
                        </template>

                        <SimpleKeyboard
                          class="keyboard"
                          @onChange="onChange"
                          @onKeyPress="onKeyPress"
                          
                        ></SimpleKeyboard>
                      </v-menu>
                    </v-col>

                    <v-col cols="6">
                      <v-menu offset-y :close-on-content-click="false">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="address1"
                            label="Street Address"
                            required
                            @click="setInputField('address1')"
                            v-bind="attrs"
                            v-on="on"
                            autocomplete="chrome-off"
                          ></v-text-field>
                        </template>
                        <SimpleKeyboard
                          class="keyboard"
                          @onChange="onChange"
                          @onKeyPress="onKeyPress"
                          
                        ></SimpleKeyboard>
                      </v-menu>
                    </v-col>

                    <v-col cols="6">
                      <v-menu offset-y :close-on-content-click="false">
                        <template v-slot:activator="{ on, attrs }">
                          <validation-provider
                            v-slot="{ errors }"
                            name="email"
                            rules="required|email"
                          >
                            <v-text-field
                              v-model="email"
                              label="Email"
                              required
                              :error-messages="errors"
                              @click="setInputField('email')"
                              autocomplete="chrome-off"
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </validation-provider>
                        </template>
                        <SimpleKeyboard
                          class="keyboard"
                          @onChange="onChange"
                          @onKeyPress="onKeyPress"
                          
                        ></SimpleKeyboard>
                      </v-menu>
                    </v-col>

                    <v-col cols="6">
                      <v-menu offset-y :close-on-content-click="false">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="address2"
                            label="City, State and Zip Code"
                            required
                            @click="setInputField('address2')"
                            autocomplete="chrome-off"
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <SimpleKeyboard
                          class="keyboard"
                          @onChange="onChange"
                          @onKeyPress="onKeyPress"
                          
                        ></SimpleKeyboard>
                      </v-menu>
                    </v-col>
                    <v-col cols="6">
                      <v-menu offset-y :close-on-content-click="false">
                        <template v-slot:activator="{ on, attrs }">
                          <validation-provider
                            v-slot="{ errors }"
                            name="number"
                            :rules="{
                              required: true,
                              digits: 10,
                            }"
                          >
                            <v-text-field
                              v-model="number"
                              label="Contact Number"
                              required
                              :error-messages="errors"
                              @click="setInputField('number')"
                              autocomplete="chrome-off"
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </validation-provider>
                        </template>
                        <SimpleKeyboard
                          class="keyboard"
                          @onChange="onChange"
                          @onKeyPress="onKeyPress"
                          
                        ></SimpleKeyboard>
                      </v-menu>
                    </v-col>

                    <v-col align="right" cols="12">
                      <v-btn
                        @click="
                          e1 = 2;
                          speechPage2();
                        "
                        width="180"
                        height="60"
                        :disabled="invalid"
                        color="primary"
                        tile
                      >
                        <span class="text-h6"> Continue </span>
                        <v-icon right dark large> mdi-arrow-right </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </validation-observer>
            </v-stepper-content>
            <v-stepper-content step="2">
              <v-form>
                <v-row>
                  <v-col cols="6">
                    <v-menu offset-y :close-on-content-click="false">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="judge"
                          label="Judge Name"
                          @click="
                            speech();
                            setInputField('judge');
                          "
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <SimpleKeyboard
                        class="keyboard"
                        @onChange="onChange"
                        @onKeyPress="onKeyPress"
                        :input="input"
                      ></SimpleKeyboard>
                    </v-menu>
                  </v-col>
                  <v-col cols="6">
                    <v-menu offset-y :close-on-content-click="false">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="caseNum"
                          label="Case Number"
                          @click="
                            speech1();
                            setInputField('caseNum');
                          "
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <SimpleKeyboard
                        class="keyboard"
                        @onChange="onChange"
                        @onKeyPress="onKeyPress"
                        :input="input"
                      ></SimpleKeyboard>
                    </v-menu>
                  </v-col>
                  <v-col cols="12">
                    <v-card flat>
                      <v-card-title
                        class="primary justify-center text-h4 white--text"
                        >Motion for Request</v-card-title
                      >
                      <span
                        class="
                          d-flex
                          justify-left
                          pt-4
                          pl-4
                          black--text
                          text-subtitle-1
                        "
                      >
                        I have a traffic court case and I am requesting a court
                        date before the Traffic Magistrate
                      </span>

                      <v-radio-group row v-model="selected" class="pl-2">
                        <v-radio value="in-person">
                          <template v-slot:label>
                            <span class="black--text font-weight-bold">
                              In person hearing requested
                            </span>
                          </template>
                        </v-radio>

                        <v-radio value="remote">
                          <template v-slot:label>
                            <span class="black--text font-weight-bold">
                              Zoom/remote hearing requested (If Eligible)
                            </span>
                          </template>
                        </v-radio>
                      </v-radio-group>

                      <span
                        class="
                          d-flex
                          justify-left
                          pl-4
                          black--text
                          text-subtitle-1
                        "
                      >
                        Note:- Please select one, but please note that all cases
                        do not qualify for a Zoom/remote hearing
                      </span>
                    </v-card>
                  </v-col>
                  <v-col cols="6" align="left">
                    <v-btn
                      @click="e1 = 1"
                      width="180"
                      height="60"
                      color="primary"
                      tile
                    >
                      Back
                    </v-btn>
                  </v-col>
                  <v-col cols="6" align="right">
                    <v-btn
                      @click="
                        e1 = 3;
                        speechPage3();
                      "
                      width="180"
                      height="60"
                      color="primary"
                      tile
                    >
                      <span class="text-h5">Continue</span>
                      <v-icon right dark large> mdi-arrow-right </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-stepper-content>
            <v-stepper-content step="3">
              <v-row>
                <v-col cols="12" align="left">
                  <span class="text-h5">Signature:</span>
                </v-col>
                <v-col cols="3" align="left">
                  <!-- <VueSignaturePad width="100%" height="300px" ref="signatureUser" /> -->
                  <VueSignaturePad
                    v-if="e1 !== 1 && e1 !== 2"
                    id="signature"
                    ref="signatureUser"
                    width="100%"
                    height="300px"
                    :options="options"
                  >
                    <canvas></canvas>
                  </VueSignaturePad>
                </v-col>
                <v-col cols="12" align="left">
                  <v-btn @click="clear()"> Clear Signature </v-btn>
                </v-col>
                <v-col cols="12">
                  <v-checkbox
                    v-model="checkbox"
                    required
                    style="margin-left: 1em"
                  >
                    <template v-slot:label>
                      <span>
                        Please check this box verifying that you are requesting
                        this hearing on behalf of yourself
                      </span>
                    </template>
                  </v-checkbox>
                </v-col>
                <v-col cols="6" align="left">
                  <v-btn
                    @click="e1 = 1"
                    width="180"
                    height="60"
                    color="primary"
                    tile
                  >
                    Back
                  </v-btn>
                </v-col>
                <v-col cols="6" align="right">
                  <v-btn
                    width="180"
                    height="60"
                    color="primary"
                    tile
                    @click="submitInfo()"
                  >
                    <span class="text-h5">Submit</span>
                    <v-icon right dark large> mdi-arrow-right </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import SimpleKeyboard from "@/components/SimpleKeyboard";
//import SignaturePad from "@/components/signaturePad";
import { required, digits, email, max, regex } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

setInteractionMode("eager");

extend("digits", {
  ...digits,
  message: "{_field_} needs to be {length} digits. ({_value_})",
});

extend("required", {
  ...required,
  message: "{_field_} can not be empty",
});

extend("max", {
  ...max,
  message: "{_field_} may not be greater than {length} characters",
});

extend("regex", {
  ...regex,
  message: "{_field_} {_value_} does not match {regex}",
});

extend("email", {
  ...email,
  message: "Email must be valid",
});

export default {
  name: "digitalForms",
  data() {
    return {
      input1: "",
      e1: 1,
      name: "",
      address1: "",
      address2: "",
      email: "",
      number: "",
      caseNum: "",
      judge: "",
      checkbox: "",
      request: "",
      keyboard: null,
      inputInFocus: null,
      inputField: null,
      selected: "",
      date: this.dateCurrent(),
      options: {
        penColor: "#1976D2",
      },
      counter: 0,
      nameRules: [(v) => !!v || "Name is required"],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
    };
  },
  components: {
    SimpleKeyboard,
    ValidationProvider,
    ValidationObserver,
    //SignaturePad
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    dateCurrent() {
      const today = new Date();
      this.date =
        today.getMonth() +
        1 +
        "/" +
        today.getDate() +
        "/" +
        today.getFullYear();
      console.log("Aaj ki date", today.getMonth() + 1);
      return this.date;
    },
    clear() {
      this.$refs.signatureUser.undoSignature();
    },
    submitInfo() {
      // clientApp.formSubmitted = true
      this.$router.push("/");
      const { isEmpty, data } = this.$refs.signatureUser.saveSignature();
      console.log("What is inside data", data);
      const requestNum = "MR" + this.counter + 1;

      if (this.selected !== "remote") {
        this.request = "In person hearing requested";
      } else {
        this.request = "Zoom/Remote hearing requested";
      }
      const form = {
        Request_Number: requestNum,
        Name: this.name,
        Address: this.address1 + this.address2,
        Email: this.email,
        Contact: this.number,
        Case_Number: this.caseNum,
        Judge_Name: this.judge,
        Request: this.request,
        Image: data,
      };
      console.log(form);
    },
    onKeyPress(button) {
      if (button === "{bksp}") {
        if (this.inputField === "name") {
          this.name = this.name.slice(0, -1);
        } else if (this.inputField === "address1") {
          this.address1 = this.address1.slice(0, -1);
        } else if (this.inputField === "address2") {
          this.address2 = this.address2.slice(0, -1);
        } else if (this.inputField === "email") {
          this.email = this.email.slice(0, -1);
        } else if (this.inputField === "number") {
          this.number = this.number.slice(0, -1);
        } else if (this.inputField === "judge") {
          this.judge = this.judge.slice(0, -1);
        } else if (this.inputField === "caseNum") {
          this.caseNum = this.caseNum.slice(0, -1);
        } else if (this.inputField === "request") {
          this.request = this.request.slice(0, -1);
        }
      }
    },

    start() {
      //clientApp.forms = false
      // rec.startRec()
    },
    speech() {
      this.$root.avatar.speak(
        "Fill in the Judge Name if known, or else leave it blank."
      );
    },
    speech1() {
      this.$root.avatar.speak(
        "Fill in the Case Number if known, or else leave it blank."
      );
    },
    speechPage2() {
      this.$root.avatar.speak(
        "In the Motion Request box select in person hearing or Zoom hearing."
      );
    },
    speechPage3() {
      this.$root.avatar.speak(
        "Please sign your name in the box, then check in the below box verifying that you are requesting this hearing on behalf of yourself."
      );
    },
    setInputField(input) {
      this.inputField = "";
      this.inputField = input;
      if (this.inputField === "name") {
        //this.input1 = this.name
        input = this.name;
      } else if (this.inputField === "address1") {
        input = this.address1;
      } else if (this.inputField === "address2") {
        input = this.address2;
      } else if (this.inputField === "email") {
        input = this.email;
      } else if (this.inputField === "number") {
        input = this.number;
      } else if (this.inputField === "judge") {
        input = this.judge;
      } else if (this.inputField === "caseNum") {
        input = this.caseNum;
      } else if (this.inputField === "request") {
        input = this.request;
      }
    },

    onChange(input) {
     // console.log('on change',input)
      if (this.inputField === "name") {
        console.log('Inside name',input)
        this.name = input;
      } else if (this.inputField === "address1") {
        console.log('Inside address1',input)
        this.address1 = input;
      } else if (this.inputField === "address2") {
        this.address2 = input;
      } else if (this.inputField === "email") {
        this.email = input;
      } else if (this.inputField === "number") {
        this.number = input;
      } else if (this.inputField === "judge") {
        this.judge = input;
      } else if (this.inputField === "caseNum") {
        this.caseNum = input;
      } else if (this.inputField === "request") {
        this.request = input;
      }
    },
    onInputChange(input) {
      console.log('On Input change', input.target.value)
      this.input = "";
      // if (this.inputField === "name") {
      //   this.name = input.target.value;
      // } else if (this.inputField === "address1") {
      //   this.address1 = input.target.value;
      // } else if (this.inputField === "address2") {
      //   this.address2 = input.target.value;
      // } else if (this.inputField === "email") {
      //   this.email = input.target.value;
      // } else if (this.inputField === "number") {
      //   this.number = input.target.value;
      // } else if (this.inputField === "judge") {
      //   this.judge = input.target.value;
      // } else if (this.inputField === "caseNum") {
      //   this.caseNum = input.target.value;
      // } else if (this.inputField === "request") {
      //   this.request = input.target.value;
      // }
      // console.log("On Input Change ", input.target.value);
    },
    handleInputFocus: function (evt) {
      this.inputInFocus = evt.target;
    },
  },
  mounted() {
    //clientApp.forms = true
    //rec.stopRec()
    //   const that = this;
    //    const urlParams = new URLSearchParams(window.location.search);
    // const speakText = urlParams.has("speakText")
    //   ? urlParams.get("speakText")
    //   : "You need to request a new hearing. Please fill out this Motion for Request Form.Tap on the entry fields and use the keyboard to complete." +
    //     "Enter your personal information on this screen";
    // setTimeout(() => {
    //   that.$root.avatar.speak(speakText);
    // }, 1000);
  },
  watch: {
    $route(to, from) {
      if (to.name === "Form") {
        if (this.updateUIFromQueryString()) {
          const urlParams = new URLSearchParams(window.location.search);
          if (urlParams.has("speakText")) {
            const that = this;
            const speakText = urlParams.get("speakText");
            setTimeout(() => {
              that.$root.avatar.speak(speakText);
            }, 1000);
          }
        }
      }
    },
  },
};
</script>

<style scoped>
#signature {
  border: double 3px transparent;
  border-radius: 5px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #4bc5e8, #9f6274);
  background-origin: border-box;
  background-clip: content-box, border-box;
}
</style>
