import {
    floorEnglishQuery,
    mapEnglishQuery,
    faqEnglishQuery,
    faqCategoryEnglishQuery,
    homepageEnglishQuery,
    departmentEnglishQuery,
    onlineServiceEnglishQuery
} from '@/graphql/query';

export default {
    state: {
        homepageEnglish: [],
        floorsEnglish: [],
        mapsEnglish: new Map(),
        faqsEnglish: [],
        faqCategoryEnglish: [],
        departmentsEnglish: [],
        onlineServicesEnglish: []
    },
    getters: {
        getHomepageEnglish: state => state.homepageEnglish,
        getFloorsEnglish: state => state.floorsEnglish,
        getMapsEnglish: state => state.mapsEnglish,
        getFaqsEnglish: state => state.faqsEnglish,
        getFaqCategoriesEnglish: state => state.faqCategoryEnglish,
        getDepartmentsEnglish: state => state.departmentsEnglish,
        getOnlineServicesEnglish: state => state.onlineServicesEnglish
    },
    mutations: {
        setHomepageEnglish(state, page) {
            state.homepageEnglish.push(page)
        },
        setFloorsEnglish(state, floor) {
            console.log(floor)
            state.floorsEnglish.push(floor)
        },
        setMapsEnglish(state, map) {
            state.mapsEnglish.set(map.mapName.toLowerCase().trim(), map)
        },
        setFaqsEnglish(state, faqs) {
            faqs.forEach(faq => {
                let data = {
                    question: faq.question,
                    answer: faq.answer.replace(/\n/g, '<br />'),
                    category: faq.category,
                    showMap: faq.showMap,
                    mapName: faq.mapName
                }
                state.faqsEnglish.push(data)
            })
        },
        setFaqCategoryEnglish(state, faqCategories) {
            state.faqCategoryEnglish = faqCategories
        },
        setDepartmentsEnglish(state, department) {
            state.departmentsEnglish.push(department)
        },
        setOnlineServicesEnglish(state, onlineService) {
            state.onlineServicesEnglish.push(onlineService)
        }
    },
    actions: {
        fetchHomePageEnglish({ commit, getters }) {
            console.log('Kiosk Type', getters.getKioskType)
            getters.getApolloClient.query({
                query: homepageEnglishQuery
            }).then(items => {
                console.log(items)
                items.data.homepageKioskMains.forEach(page => {
                    commit('setHomepageEnglish', page);
                })

            })
        },

        // Fetch Floor from strapi
        fetchFloorsEnglish({ commit, dispatch, getters }) {
            console.log('Inside Fetch Floors english')

            getters.getApolloClient.query({
                query: floorEnglishQuery
            }).then(floors => {
                floors.data.floorKioskSubs.forEach(floor => {
                    dispatch('saveInCache', floor.floorImage.url)
                        .then(response => {
                            floor.floorImage.url = response;
                            commit('setFloorsEnglish', floor)
                        })
                })

            })
        },

        // Fetching Maps from Strapi
        fetchMapsEnglish({ commit, getters, dispatch }) {

            getters.getApolloClient.query({
                query: mapEnglishQuery
            }).then(maps => {
                console.log(maps)
                maps.data.mapKioskSubs.forEach(map => {
                    dispatch('saveInCache', map.mapImage.url)
                        .then(response => {
                            commit('setMapsEnglish', {
                                mapName: map.mapName.trim(),
                                mapFloor: map.mapFloor,
                                mapImage: response,
                                speech: map.speech
                            })
                        })
                })
            })
        },

        fetchFaqsEnglish({ commit, getters }) {

            getters.getApolloClient.query({
                query: faqEnglishQuery
            }).then(faqs => {
                console.log(faqs.data.faqs)
                commit('setFaqsEnglish', faqs.data.faqs);

            })
        },

        fetchFaqCategoryEnglish({ commit, getters }) {
            getters.getApolloClient.query({
                query: faqCategoryEnglishQuery
            }).then(faqCategory => {
                commit('setFaqCategoryEnglish', faqCategory.data.faqCategories)

            })
        },

        fetchDepartmentsEnglish({ commit, getters, dispatch }) {
            getters.getApolloClient.query({
                query: departmentEnglishQuery
            }).then(departments => {
                departments.data.departmentKioskSubs.forEach(department => {
                    dispatch('saveInCache', department.departmentMap.url)
                        .then(response => {
                            commit('setDepartmentsEnglish', {
                                departmentName: department.departmentName,
                                departmentImage: response,
                                speech: department.speech
                            })
                        })
                })
            })
        },

        fetchOnlineServicesEnglish({ commit, getters }) {
            getters.getApolloClient.query({
                query: onlineServiceEnglishQuery
            }).then(services => {
                services.data.onlineServices.forEach(service => {
                    commit('setOnlineServicesEnglish', {
                        serviceName: service.serviceName,
                        serviceLink: service.serviceLink,
                    })
                })
            })
        }

    }
}
