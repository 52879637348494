import gql from 'graphql-tag'

//---------------------------- HomePage Queries ---------------------------------

export const homepageSpanishQuery = gql`
query homeQuery {
  homepageKioskMains (sort: "displayPosition", locale: "es-US") {
    id
    speech    
    menuName     
    pageType{
      ... on ComponentHomepageNavigation{
        __typename
        pathName
        pageHeader
      }
      ... on ComponentHomepageDialogBox{
        __typename
        type
        textList      
      }
    }
    displayIcon
    displayPosition
  }
}
`

//---------------------------- Faq Page Queries ---------------------------------

export const faqSpanishQuery = gql`
query faqQuery {
  faqs (locale: "es-US") {
    id
    question
    answer
    category
    showMap
    mapName
  }
}
`

export const faqCategorySpanishQuery = gql`
query faqCategoryQuery {
  faqCategories (locale: "es-US") {
    id
    categoryName
    displayType
  }
}
`
//---------------------------- Map Page Queries ---------------------------------



export const mapSpanishQuery = gql`
query mapQuery {
  mapKioskSubs (locale: "es-US") {
    id
    mapName
    mapFloor
    speech
    mapImage {
      id
      url
    }
  }
}
`

export const floorSpanishQuery = gql`
query floorQuery {
  floorKioskSubs (sort: "floorNumber", locale: "es-US"){ 
    id
    floorName
    floorNumber
    speech
    floorImage {
      id
      url
    }
  }
}
`

export const departmentSpanishQuery = gql`
query departmentQuery {
  departmentKioskSubs(locale: "es-US") { 
    id
  	departmentName
    speech
    departmentMap{
      id
      url
    }
  }
}
`

export const onlineServiceSpanishQuery = gql`
query onlineServiceQuery {
  onlineServices(locale: "es-US") { 
    id
  	serviceName
    serviceLink
  }
}
`