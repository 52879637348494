<template>
  <v-row no-gutters>
    <v-col class="mr-5" v-if="showAvatar" cols="1">
      <avatar :initialWidth="190"></avatar>
    </v-col>
    <v-col>
      <v-card
        class="bubbleBorder px-5"
        height="180"
        color="rgba(30, 101, 39, 0.88)"
      >
        <v-row no-gutters class="fill-height ma-0 pa-0">
          <v-col class="px-5" align="center" align-self="center">
            <span
              :class="{ 'text-h4': getTextBubbleText.length >= 200 }"
              class="white--text text-h3 font-weight-medium pl-5"
            >
              {{ getTextBubbleText }}
            </span>
          </v-col>
          <v-col cols="1" align="center" align-self="center">
            <v-img
              class="talk-button"
              @click="actionOnTap()"
              contain
              width="140px"
              :src="require('../assets/' + talkButtonSrc)"
            >
            </v-img>
            <span class="white--text headline">
              {{ speakingStatus }}
            </span>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-col
      v-if="showAvatar"
      class="d-flex mt-n7"
      align="center"
      justify="center"
      cols="12"
    >
      <v-icon
        size="80"
        class="ml-10"
        @click="
          $router.push('/');
          backHome();
        "
        color="primary"
      >
        mdi-arrow-left-circle
      </v-icon>
      <v-spacer></v-spacer>
      <span class="text-h3 ml-n10 font-weight-bold my-auto">
        {{ pageHeader }}
      </span>
      <v-spacer></v-spacer>
    </v-col>
  </v-row>
</template>

<script>
import avatar from "@/components/avatar";
import { mapGetters } from "vuex";

export default {
  name: "appHeader",
  components: {
    avatar,
  },
  data() {
    return {
      showAvatar: false,
      pageHeader: "",
      changeFont: false,
      speakingStatus: "Tap To Talk",
      talkButtonSrc: "talkButton.png",
    };
  },
  computed: {
    ...mapGetters([
      "getTextBubbleText",
      "getPageHeader",
      "getIsSpeaking",
      "speechRecognitionStatus",
      "getQnaStatus",
      "getTouchCount",
      "isUserLooking",
    ]),
  },
  watch: {
    $route(to, from) {
      console.log("ROUTE TO", to.path);
      console.log("ROUTE FROM", from.path);
      if (to.path !== "/") {
        this.showAvatar = true;
      } else {
        this.showAvatar = false;
      }
    },
    getPageHeader(newVal) {
      this.pageHeader = newVal;
    },
    getIsSpeaking(newVal) {
      if (newVal) {
        this.talkButtonSrc = "stopTalking.png";
        this.speakingStatus = "Speaking";
      }
      if (!newVal && !this.speechRecognitionStatus) {
        this.talkButtonSrc = "talkButton.png";
        this.speakingStatus = "Tap To Talk";
      }
    },
    speechRecognitionStatus(newVal) {
      if (newVal) {
        this.speakingStatus = "Listening";
        this.talkButtonSrc = "talkButtonActive.webp";
      } else {
        this.talkButtonSrc = "talkButton.png";
        this.speakingStatus = "Tap To Talk";
      }
    },
    getQnaStatus(newVal) {
      if (newVal) {
        this.speakingStatus = "Thinking";
        this.talkButtonSrc = "talkButton.png";
      } else {
        this.talkButtonSrc = "talkButton.png";
        this.speakingStatus = "Tap To Talk";
      }
    },
  },
  methods: {
    actionOnTap() {
      if (this.speakingStatus === "Speaking") {
        window.stopSpeech();

        this.$store.commit("setTouchRequest", {
          module: "Default",
          action: "Touch Detected",
          response: "Stop Speech Initiated",
          timeStamp: new Date(),
          requestType: "Touch",
        });
      } else {
        if (this.$i18n.locale === "en") {
          this.$store.commit("setSpeechRecognitionHandler", true);

          // To start session using touch - when no face detected
          if (!this.isUserLooking) {
            console.log("User Session started");
            this.$store.commit("setTouchCount", this.getTouchCount + 1);
            if (this.getTouchCount === 1) {
              // start time
              this.$store.commit("setStartUserSession", new Date());

              //session start object
              this.$store.commit("setTouchRequest", {
                module: "Default",
                action: "Touch Detected",
                response: "User Session Started - Touch Detected",
                timeStamp: new Date(),
                requestType: "Session",
              });
            }
          }
        } else {
          if (!this.getIsSpeaking) {
            this.$store.dispatch(
              "avatarSpeak",
              "Sé hablar en español, pero todavía estoy aprendiendo a escuchar en español."
            );
          } else {
            window.stopSpeech();
          }
        }
      }
    },
    backHome() {
      window.stopSpeech();
      // Touch Usage
      //this.$store.commit('setTextBubbleText', 'Tap the microphone button, to ask me a question.');
      this.$store.commit(
        "setTextBubbleText",
        this.$i18n.t("bubbletextafteraction")
      );
      this.$store.commit("setTouchRequest", {
        module: "App Header",
        action: "navigate",
        response: "Home Page",
        timeStamp: new Date(),
        requestType: "Touch",
      });
    },
  },
};
</script>

<style scoped>
.bubbleBorder {
  border-radius: 0 0 30px 30px !important;
}
.talk-button {
  filter: sepia(19%) saturate(697%) hue-rotate(294deg) brightness(95%)
    contrast(350%);
}
</style>

<i18n>
{
  "en": {
      "bubbletextafteraction": "Tap the microphone button, to ask me a question."
    },
  "es":{
      "bubbletextafteraction": "El reconocimiento de voz está desactivado para el idioma español."
    }
}
</i18n>
